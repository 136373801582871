import React, { useState, useRef, useEffect } from "react";
import citiesMap from "../../assets/utils/cities.json";
import "./travel-search.style.css";
import TrainSearch from "../trains-search/train-search";
import { addDays, format, parse } from "date-fns";
import { it } from "date-fns/locale";
import flightIcon from "../../assets/air-ticket-icon.png";
import ticketIcon from "../../assets/ticket-icon.png";
import ExpediaHotels from "../expedia-url/expedia";
import BookingHotels from "../booking/booking-hotels";
import OmioTravel from "../omio-travel/omioTravel";
import Flags from "../flags/flags";

const cities = Object.entries(citiesMap.countries).flatMap(
  ([countryCode, country]) =>
    Object.values(country.cities).map((city) => ({
      name: city.name,
      region: city.region,
      airports: city.airports,
      stations: city.stations,
      country: city?.country,
    }))
);
const apiKey = "PVE2h9APwvCrttCNfJO3Gr6oCgAQPpeo"; // Sostituisci con la tua API Key
const apiSecret = "VSfKyPTQq06nb2T5";

const TravelSearch = ({ festival }) => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [token, setToken] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const inputRef = useRef();
  const [selectedCity, setSelectedCity] = useState(null);
  const [error, setError] = useState(null);
  const [flightOffers, setFlightOffers] = useState([]);
  const [selectedNation, setSelectedNation] = useState(null);
  const festivalCity = cities.find((city) => {
    return city?.name === festival?.city;
  });
  const [selectedTicket, setSelectedTicket] = useState();
  const tickets = festival?.tickets;

  const normalizeString = (str) => {
    return str
      .normalize('NFD')                // Decompone i caratteri nei loro componenti base
      .replace(/[\u0300-\u036f]/g, '') // Rimuove i diacritici
      .toLowerCase();                  // Converte in minuscolo per un confronto case-insensitive
  };

  const handleTicket = (index) => {
    setSelectedTicket(tickets[index]);

    if (selectedCity) fetchFlightOffers(selectedCity);
  };
  const generateTripFlightUrl = (date, returnDate) => {
    const params = new URLSearchParams({
      ddate: date, // es: '2024-11-08'
      dcity:
        selectedCity?.airports?.length > 1
          ? selectedCity?.name.toLowerCase().substring(0, 3)
          : selectedCity.airports[0]?.iata_code, // es: 'ROM'
      dairport: selectedCity.airports[0]?.iata_code, // es: 'FCO'
      acity:
        festivalCity?.airports?.length > 1
          ? festivalCity?.name.toLowerCase().substring(0, 3)
          : festivalCity?.airports[0]?.iata_code, // es: 'MIL'
      aairport: festivalCity?.airports[0]?.iata_code, // es: 'LIN'
      adate: returnDate, // es: '2024-11-11'
      triptype: "1", // round trip
      classtype: "0", // economy
      classgroupsearch: "true",
      adult: "1",
      child: "0",
      infant: "0",
      stoptype: "0",
    });
    return `https://trip.com/m/flights/showfarefirst?${params.toString()}`;
  };
  async function getAccessToken() {
    const response = await fetch(
      "https://test.api.amadeus.com/v1/security/oauth2/token",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          grant_type: "client_credentials",
          client_id: apiKey,
          client_secret: apiSecret,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }

    const data = await response.json();

    return data.access_token;
  }

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await getAccessToken();
        setToken(token);
        console.log("[Amadeus] Token acquired");
      } catch (error) {
        console.error("Error fetching access token:", error);
      }
    };

    fetchToken();
  }, []);

  const fetchFlightOffers = async (city) => {
    if (!token) return;
    setLoading(true);
    try {
      const response = await fetch(
        `https://test.api.amadeus.com/v2/shopping/flight-offers?originLocationCode=${
          city?.airports[0]?.iata_code
        }&destinationLocationCode=${
          festivalCity?.country === city?.country
            ? festivalCity?.airports[0]?.iata_code
            : festivalCity?.airports[1]
            ? festivalCity?.airports[1]?.iata_code
            : festivalCity?.airports[0]?.iata_code
        }&departureDate=${format(
          parse(selectedTicket?.startDate, "dd/MM/yyyy", new Date()),
          "yyyy-MM-dd"
        )}&returnDate=${format(
          addDays(parse(selectedTicket?.endDate, "dd/MM/yyyy", new Date()), 1),
          "yyyy-MM-dd"
        )}&adults=1&max=250`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        setError(response);
        setFlightOffers([]);
        setLoading(false);
        console.log(error);

        throw new Error("Network response was not ok " + response.statusText);
      }

      const data = await response.json();
      setFlightOffers(data);
      setLoading(false);
      //   setCarriers(data?.dictionaries?.carriers); // Imposta le compagnie aeree
    } catch (error) {
      console.error("Error fetching flight offers:", error);
    }
  };

 useEffect(() => {
  const handleQuery = (query) => {
    if (!selectedNation) return;
    
    // Normalizziamo la query
    const normalizedQuery = normalizeString(query || '');

    const results = Object.values(citiesMap.countries[selectedNation].cities)
      .filter((city) => normalizeString(city.name).includes(normalizedQuery));

    query.length > 1 ? setFilteredCities(results) : setFilteredCities([]);
    selectedCity?.name !== query && setSelectedCity(null);
    setFlightOffers([]);
  };

  handleQuery(query);
}, [query, selectedCity, selectedNation]);

  const formatStartDate = (date) => {
    const parsedStartDate = parse(date, "dd/MM/yyyy", new Date());
    const formattedStartdDate = format(parsedStartDate, "d MMMM yyyy", {
      locale: it,
    });
    return formattedStartdDate;
  };

  const formatEndDate = (date) => {
    const parsedEndDate = parse(date, "dd/MM/yyyy", new Date());
    const formattedEnddDate = format(parsedEndDate, "d MMMM yyyy", {
      locale: it,
    });
    return formattedEnddDate;
  };

  return (
    <>
      {tickets && (
        <span style={{ marginBottom: "0.5rem" }}>Seleziona la data</span>
      )}

      {tickets && tickets.length > 0 && (
        <div className="ticket-results">
          {tickets.map((ticket, index) => (
            <div
              className={`ticket ${
                ticket.minPrice === selectedTicket?.minPrice ? "selected" : ""
              } ${
                index === 0 && festival?.tickets?.length === 1
                  ? "max-width"
                  : ""
              }`}
              key={index}
              onClick={() => {
                if (selectedTicket?.minPrice === ticket?.minPrice) return;
                setSelectedTicket(ticket);
                handleTicket(index);
              }}
            >
              <span className="ticket-date">
                {ticket?.startDate !== ticket?.endDate
                  ? ` Dal ${formatStartDate(
                      ticket?.startDate
                    )} al ${formatEndDate(ticket?.endDate)}`
                  : `${formatStartDate(ticket?.startDate)}`}
              </span>
              <div className="lineup">
                {selectedTicket?.minPrice === ticket?.minPrice ? (
                  ticket.lineup.map((artist, index) => (
                    <div className="artist selected-artist" key={index}>
                      {index < 1
                        ? artist
                        : selectedTicket?.minPrice === ticket?.minPrice &&
                          artist}
                    </div>
                  ))
                ) : (
                  <div className="artist">{ticket.lineup[0]}</div>
                )}
              </div>
              <span>e molti altri...</span>
              {ticket?.minPrice === selectedTicket?.minPrice && (
                <a
                  className="ticket-link"
                  href={ticket?.ticketLink}
                  target="_blank"
                  rel="sponsored noopener noreferrer"
                >
                  <img
                    src={ticketIcon}
                    alt="ticket-icon"
                    className="flight-icon"
                  />
                  <span className="buy-ticket">
                    <span style={{ color: "#FFA666" }}>Acquista</span> su{" "}
                    {ticket?.vendor}
                  </span>
                </a>
              )}
            </div>
          ))}
        </div>
      )}
      {selectedTicket && (
        <>
        <span  style={{ marginBottom: "0.5rem" }}>Seleziona la nazione di partenza</span>
          
          <div className="nations">
            {Object.entries(citiesMap.countries).map(([code, country]) => {
              // Mapping delle bandiere in base al codice paese
              const FlagComponent = code === 'IT' ? Flags.ItalyFlag :
                                  code === 'UK' ? Flags.UKFlag :
                                  code === 'DE' ? Flags.GermanyFlag :
                                  code === 'ES' ? Flags.SpainFlag :
                                  code === 'FR' ? Flags.FranceFlag : null;
              return (
                <button
                  key={code}
                  onClick={() => {
                    setSelectedNation(code);
                    setQuery("");
                    setSelectedCity(null);
                    setFilteredCities([]);
                    setTimeout(() => {
                      inputRef.current?.focus();
                    }, 0);
                  }}
                  className={`travel-departure ${
                    selectedNation === code 
                      ? "selected-departure nation"
                      : "nation"
                  }`}
                >
                  {FlagComponent && <FlagComponent />}
                  <span>{country.name}</span>
                </button>
              );
            })}
          </div>
        </>
      )}
      {selectedTicket && selectedNation && (
        <>
          <span style={{ marginBottom: "0.5rem" }}>
            Inserisci la città di partenza
          </span>
          <input
            className="input-placeholder"
            type="text"
            placeholder="Città di partenza"
            onChange={(e) => setQuery(e.target.value)}
            value={query}
            ref={inputRef}
          />
        </>
      )}

      {filteredCities.length > 0 && (
        <div className="travel-results">
          {filteredCities.map(
            (city, index) =>
              city?.name !== festivalCity?.name && (
                <div
                  className={`travel-departure ${
                    city.name === query && selectedCity?.name === city.name
                      ? "selected-departure"
                      : ""
                  }`}
                  key={index}
                  onClick={() => {
                    if (selectedCity?.name === city.name) return;
                    setSelectedCity(city);
                    setQuery(city?.name);
                    if (city?.airports?.length > 0) fetchFlightOffers(city);
                  }}
                >
                  {city?.name} ({city?.region})
                </div>
              )
          )}
        </div>
      )}
      {selectedCity && !loading && query === selectedCity?.name && (
        <span>Opzioni di viaggio</span>
      )}
      {loading && <span>Carico le soluzioni di viaggio</span>}

      <div className="travel-results">
        <div className="trip-options">
          {selectedCity && !loading && query === selectedCity?.name && (
            <div className="trip-date-container">
              <span className="trip-date">{selectedTicket?.startDate}</span>
              <div className="trip-date-divider"></div>
              <span className="trip-date">
                {format(
                  addDays(
                    parse(selectedTicket?.endDate, "dd/MM/yyyy", new Date()),
                    1
                  ),
                  "dd/MM/yyyy"
                )}
              </span>
            </div>
          )}
          {selectedCity &&
          !loading &&
          query === selectedCity?.name &&
          selectedCity?.region !== "Sicilia" &&
          selectedCity?.country === festivalCity?.country ? (
            <TrainSearch
              departure={{
                name: selectedCity?.name,
                code: selectedCity?.stations[0]?.code,
                stationName: selectedCity?.stations[0]?.name,
              }}
              arrival={{
                code: festivalCity?.stations[0]?.code,
                name: festivalCity?.name,
                stationName: festivalCity?.stations[0]?.name,
              }}
              date={format(
                parse(selectedTicket?.startDate, "dd/MM/yyyy", new Date()),
                "yyyy-MM-dd"
              )}
              returnDate={format(
                addDays(
                  parse(selectedTicket?.endDate, "dd/MM/yyyy", new Date()),
                  1
                ),
                "yyyy-MM-dd"
              )}
            />
          ) : (
            selectedCity &&
            !loading &&
            query === selectedCity?.name &&
            flightOffers?.data?.length === 0 && (
              <span>Nessuna opzione trovata</span>
            )
          )}

          {!loading &&
            selectedCity?.airports?.length > 0 &&
            festivalCity?.airports.length > 0 && (
              <div className="travel-offer">
                <img
                  src={flightIcon}
                  alt="flight-icon"
                  className="flight-icon"
                />
                <div className="carriers-offer">
                  <span className="airport">
                    {festivalCity?.country === selectedCity?.country
                      ? selectedCity?.airports[0]?.name
                      : selectedCity?.airports[1]?.name.length > 0
                      ? selectedCity?.airports[1]?.name
                      : selectedCity?.airports[0]?.name}
                  </span>
                  <span className="airport">
                    {festivalCity?.country === selectedCity?.country
                      ? festivalCity?.airports[0]?.name
                      : festivalCity?.airports[1]?.name.length > 0
                      ? festivalCity?.airports[1]?.name
                      : festivalCity?.airports[0]?.name}
                  </span>
                  {flightOffers?.data?.length > 0 &&
                    Object.entries(flightOffers?.dictionaries?.carriers)
                      // Prima creiamo un array di oggetti con carrier e prezzo minimo
                      .map(([code, name]) => {
                        const offersForCarrier = flightOffers?.data.filter(
                          (offer) =>
                            offer.validatingAirlineCodes[0].includes(code)
                        );

                        const lowestPriceForCarrier =
                          offersForCarrier.length > 0
                            ? offersForCarrier.reduce((lowest, offer) => {
                                const offerPrice = parseFloat(
                                  offer.price.grandTotal
                                );
                                return offerPrice < lowest
                                  ? offerPrice
                                  : lowest;
                              }, parseFloat(offersForCarrier[0].price.grandTotal))
                            : Infinity; // Usiamo Infinity per i carrier senza offerte

                        return {
                          code,
                          name,
                          lowestPrice: lowestPriceForCarrier,
                          hasOffers: offersForCarrier.length > 0,
                        };
                      })
                      // Filtriamo solo i carrier con offerte
                      .filter((carrier) => carrier.hasOffers)
                      // Ordiniamo per prezzo più basso
                      .sort((a, b) => a.lowestPrice - b.lowestPrice)
                      // Prendiamo solo i primi 3
                      .slice(0, 3)
                      // Renderizziamo i risultati
                      .map(({ name, lowestPrice }) => (
                        <span className="carrier-name" key={name}>
                          {name} <br />
                          <span>{`${lowestPrice} €`}</span>
                        </span>
                      ))}
                </div>
                <a
                  className="flight-search-button"
                  target="_blank"
                  rel="sponsored noopener noreferrer"
                  href={generateTripFlightUrl(
                    format(
                      parse(
                        selectedTicket?.startDate,
                        "dd/MM/yyyy",
                        new Date()
                      ),
                      "yyyy-MM-dd"
                    ),
                    format(
                      addDays(
                        parse(
                          selectedTicket?.endDate,
                          "dd/MM/yyyy",
                          new Date()
                        ),
                        1
                      ),
                      "yyyy-MM-dd"
                    )
                  )}
                >
                  Prenota
                  <small style={{ color: "white" }}> su Trip</small>
                </a>
              </div>
            )}
          {selectedTicket?.omioLink &&
            selectedCity &&
            flightOffers &&
            !loading &&
            selectedTicket?.omioLink?.length > 0 &&
            selectedTicket?.omioLink?.filter(
              (ticket) => selectedCity?.name === ticket?.from
            ).length > 0 && (
              <OmioTravel
                departure={selectedCity?.name}
                arrival={festivalCity?.name}
                hasAirport={selectedCity?.airports?.length > 0}
                link={
                  selectedTicket?.omioLink?.filter(
                    (ticket) => selectedCity?.name === ticket?.from
                  )[0]?.link
                }
              />
            )}
          {flightOffers?.data?.length === 0 && (
            <div>Nessuna offerta trovata</div>
          )}

          {selectedCity &&
            query === selectedCity?.name &&
            festival?.stay22Map &&
            !loading && (
              <>
                <span style={{ margin: "1rem 0" }}>Opzioni di alloggio</span>
                {selectedTicket.type === "bundle" && (
                  <ExpediaHotels
                    link={selectedTicket?.expediaLink}
                    city={festival?.location}
                    checkIn={format(
                      parse(
                        selectedTicket?.startDate,
                        "dd/MM/yyyy",
                        new Date()
                      ),
                      "yyyy-MM-dd"
                    )}
                    checkOut={format(
                      addDays(
                        parse(
                          selectedTicket?.endDate,
                          "dd/MM/yyyy",
                          new Date()
                        ),
                        1
                      ),
                      "yyyy-MM-dd"
                    )}
                  />
                )}

                <div className="stay22-map">
                  <iframe
                    title="sta22-map"
                    id="stay22-widget"
                    width="100%"
                    height="428"
                    src={
                      selectedTicket?.stay22MapTicket?.length > 0
                        ? selectedTicket?.stay22MapTicket
                        : festival?.stay22Map
                    }
                    frameBorder="0"
                  ></iframe>
                </div>
                <BookingHotels
                  city={festivalCity?.name}
                  checkIn={format(
                    parse(selectedTicket?.startDate, "dd/MM/yyyy", new Date()),
                    "yyyy-MM-dd"
                  )}
                  checkOut={format(
                    addDays(
                      parse(selectedTicket?.endDate, "dd/MM/yyyy", new Date()),
                      1
                    ),
                    "yyyy-MM-dd"
                  )}
                  link={selectedTicket?.bookingLink}
                />
              </>
            )}
        </div>
      </div>
    </>
  );
};

export default TravelSearch;
