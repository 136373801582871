import React from "react";
import "./Explore.style.css";

const Explore = () => {
  return (
    <div className="explore-container">
      Ciao
    </div>
  );
};

export default Explore;
