// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationIT from './locales/it/translation.json';
import translationEN from './locales/en/translation.json';

const resources = {
  en: {
    translation: translationEN
  },
  it: {
    translation: translationIT
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'it',
    supportedLngs: ['it', 'en'],
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'preferred-language', // chiave usata nel localStorage
      caches: ['localStorage']
    },
    react: {
      useSuspense: true // abilita il Suspense di React per il caricamento delle traduzioni
    }
  });

// Rendi i18n disponibile globalmente
window.i18n = i18n;

export default i18n;