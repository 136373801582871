// ShareButton.js
import React, { useState, useEffect } from "react";
import "./share-button.style.css";
import shareIcon from "../../assets/share-icon.png";

const ShareButton = ({ festival }) => {
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [canShare, setCanShare] = useState(false);

  useEffect(() => {
    // Verifichiamo se la Web Share API è effettivamente disponibile
    const checkShareAvailability = async () => {
      const shareData = {
        title: "Test Share",
        text: "Test Share Text",
        url: window.location.href,
      };

      // Verifichiamo sia l'esistenza dell'API che la sua effettiva funzionalità
      const isShareAvailable =
        typeof navigator.share !== "undefined" &&
        navigator.canShare &&
        navigator.canShare(shareData);

      setCanShare(isShareAvailable);
      console.log("Web Share API available:", isShareAvailable); // Debug log
    };

    checkShareAvailability();
  }, []);

  const handleShare = async () => {
    if (!festival) return;

    try {
      const title = `${festival.name || "Festival"} ${festival.year || "2025"}`;
      const lineupText = festival.lineup?.length
        ? `Line-up: ${festival.lineup.slice(0, 3).join(", ")}`
        : "";
      const text = `Scopri ${festival.name || "il festival"} a ${
        festival.city || "Italia"
      }! ${lineupText} e molti altri artisti ti aspettano!`;
      const url = window.location.href;

      const shareData = { title, text, url };

      console.log(
        "Attempting to share with:",
        canShare ? "Web Share API" : "Clipboard"
      ); // Debug log

      if (canShare) {
        try {
          await navigator.share(shareData);
          setNotificationMessage("Contenuto condiviso con successo!");
        } catch (err) {
          if (err.name !== "AbortError") {
            console.error("Share error:", err); // Debug log
            throw err;
          }
          return;
        }
      } else {
        // Fallback alla clipboard
        if (navigator.clipboard && navigator.clipboard.writeText) {
          await navigator.clipboard.writeText(url);
          setNotificationMessage("Link copiato negli appunti!");
        } else {
          const textArea = document.createElement("textarea");
          textArea.value = url;
          document.body.appendChild(textArea);
          textArea.select();

          try {
            document.execCommand("copy");
            setNotificationMessage("Link copiato negli appunti!");
          } catch (err) {
            setNotificationMessage("Impossibile copiare il link");
          } finally {
            document.body.removeChild(textArea);
          }
        }
      }
    } catch (err) {
      console.error("General error:", err); // Debug log
      setNotificationMessage(
        "Si è verificato un errore durante la condivisione"
      );
    }

    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 3000);
  };

  return (
    <div className="share-container">
      <button
        onClick={handleShare}
        className="share-button"
        aria-label="Condividi festival"
      >
        <img src={shareIcon} className="share-icon" alt="link"></img>
        Invita gli amici
      </button>

      {showNotification && (
        <div className="share-notification" role="alert">
          {notificationMessage}
        </div>
      )}
    </div>
  );
};

export default ShareButton;
