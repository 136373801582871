import React from "react";
import "./festival-card.style.css";
import background from "../../assets/festival-card.jpg";
import locationIcon from "../../assets/location.png";
import dateIcon from "../../assets/date.png";
import microphoneIcon from "../../assets/microphone.png";
import { format, parse } from "date-fns";
import { it } from "date-fns/locale";

const FestivalCard = ({ festival, mini }) => {
  const cardStyle = {
    background: "#202746",
    backgroundImage: `url(${festival?.card ? festival.card : background}`, // Add a blur and brightness effect to the background image
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    color: "#fff", // Ensure text is readable on the background image
    transition: "all 0.5s",
    textDecoration: "none", // Remove link decoration
  };
  const parsedStartDate = parse(festival?.startDate, "dd/MM/yyyy", new Date());
  const formatteStartdDate = format(parsedStartDate, "d MMMM yyyy", {
    locale: it,
  });
  const parsedEndDate = parse(festival?.endDate, "dd/MM/yyyy", new Date());
  const formatteEnddDate = format(parsedEndDate, "d MMMM yyyy", {
    locale: it,
  });
  const formattedName = festival?.name.replace(/\s+/g, "-").toLowerCase();
  return (
    <div style={cardStyle} className="festival-card card-mini">
      <a
        href={`/festival/${formattedName}`}
        rel="noopener noreferrer"
        className="festival-card-link"
      >
        <div className="festival-card-info-container">
          <div className="festival-card-name-container">
            <h3 className="card-name">{festival?.name}</h3>
          </div>
          {!mini && (
            <>
              <p className="card-date">
                <img src={locationIcon} className="icon" alt="icon"></img>
                {`${festival?.location?.join(", ")}, ${festival?.city}`}
              </p>
              <p className="card-date">
                <img src={dateIcon} className="icon" alt="icon"></img>
                {`Dal ${formatteStartdDate}`} {`al ${formatteEnddDate}`}
              </p>
              
            </>
          )}
          <p className="card-date">
                <img src={microphoneIcon} className="icon" alt="icon"></img>
                <span>
                  {festival?.lineup[0] !== "TBA"
                    ? `${festival?.lineup?.join(", ")} e molti altri...`
                    : "TBA"}
                </span>
              </p>
        </div>
      </a>
    </div>
  );
};

export default FestivalCard;
