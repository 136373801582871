// FestivalCalendar.js
import React, { useMemo } from 'react';
import { format, parse, eachMonthOfInterval } from 'date-fns';
import { it } from 'date-fns/locale';
import FestivalCard from '../festival-card/festival-card';
import './festival-calendar.style.css';

const FestivalCalendar = ({ festivals }) => {
  const festivalsByMonth = useMemo(() => {
    const months = {};
    
    festivals.forEach(festival => {
      if (!festival.startDate || !festival.endDate) return;
      
      // Parse start and end dates
      const startDate = parse(festival.startDate, 'dd/MM/yyyy', new Date());
      const endDate = parse(festival.endDate, 'dd/MM/yyyy', new Date());
      
      // Get all months between start and end date (inclusive)
      const monthsInRange = eachMonthOfInterval({
        start: startDate,
        end: endDate
      });

      // Add festival to each month in the range
      monthsInRange.forEach(date => {
        const monthKey = format(date, 'MMMM', { locale: it });
        
        if (!months[monthKey]) {
          months[monthKey] = [];
        }
        
        months[monthKey].push({
          ...festival,
          formattedDate: format(date, 'd MMMM yyyy', { locale: it })
        });
      });
    });
    
    // Sort months based on their numerical value
    return Object.entries(months).sort((a, b) => {
      const monthA = parse(a[0], 'MMMM', new Date(), { locale: it });
      const monthB = parse(b[0], 'MMMM', new Date(), { locale: it });
      return monthA - monthB;
    });
  }, [festivals]);

  const getTicketedFestivals = (monthFestivals) => {
    return monthFestivals.filter(festival => festival?.tickets?.length > 0);
  };

  return (
    <div className="w-full max-w-4xl mx-auto p-6">
      <div className="mb-8 text-center">
        <h1 className="text-3xl font-bold mb-2">Festival Calendar 2025</h1>
        <p className="text-gray-600">Scopri i migliori festival musicali</p>
      </div>

      <div className="space-y-8">
        {festivalsByMonth.map(([month, monthFestivals]) => {
          const ticketedFestivals = getTicketedFestivals(monthFestivals);
          
          // Skip rendering months without ticketed festivals
          if (ticketedFestivals.length === 0) return null;
          
          return (
            <div key={month} className="bg-white rounded-lg shadow-lg p-6">
              <div className="flex items-center justify-between mb-4">
                <h2 className="capitalize">{month}</h2>
              </div>
              
              <div className="card-container">
                {ticketedFestivals.map((festival) => (
                  <FestivalCard 
                    key={`${festival.name}-${festival.startDate}-${month}`} 
                    festival={festival} 
                    mini={true} 
                  />
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FestivalCalendar;