import React, { useState, useEffect } from 'react';
import './countdown.style.css'

const FestivalCountdown = ({ startDate }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });
  
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const calculateTimeLeft = () => {
      // Convertiamo la data dal formato DD/MM/YYYY a un oggetto Date
      const [day, month, year] = startDate.split('/');
      // I mesi in JavaScript sono 0-based, quindi sottraiamo 1 dal mese
      const festivalDate = new Date(year, month - 1, day);
      const now = new Date();
      
      const difference = festivalDate.getTime() - now.getTime();
      
      if (difference <= 0) {
        setIsExpired(true);
        return;
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      setTimeLeft({ days, hours, minutes, seconds });
    };

    if (startDate) {  // Verifichiamo che startDate sia definita
      // Calcola il tempo iniziale
      calculateTimeLeft();

      // Aggiorna ogni secondo
      const timer = setInterval(calculateTimeLeft, 1000);

      // Pulizia del timer quando il componente viene smontato
      return () => clearInterval(timer);
    }
  }, [startDate]);

  if (!startDate) {
    return null;  // Non mostrare nulla se non c'è una data
  }

  if (isExpired) {
    return <div className="text-lg font-bold">Il festival è iniziato!</div>;
  }

  return (
    <div className="w-full p-4">
      <h2 className="text-xl font-bold mb-4">Countdown al festival:</h2>
      <div className="countdown-container">
        <div className="text-center">
          <span className="time-left">{timeLeft.days}</span>
          <p>Giorni</p>
        </div>
        <div className="text-center">
          <span className="time-left">{timeLeft.hours}</span>
          <p>Ore</p>
        </div>
        <div className="text-center">
          <span className="time-left">{timeLeft.minutes}</span>
          <p>Minuti</p>
        </div>
        <div className="text-center">
          <span className="time-left">{timeLeft.seconds}</span>
          <p>Secondi</p>
        </div>
      </div>
    </div>
  );
};

export default FestivalCountdown;