import React from "react";
import { useTranslation } from "react-i18next";
import "./Home.css";
import FestivalCard from "../../components/festival-card/festival-card";
import Search from "../../components/search/search";
import festivals from "../../assets/utils/festivals.json";
import FestivalCalendar from "../../components/festival-calendar/festival.-calendar";

const Home = () => {
  const { t } = useTranslation();
  return (
     
      <div className="home-container">
      <h1 className="home-title">
        {t('home.title')}
      </h1>
        <div className="centered-text">
          <p className="home-subtitle">
            {t('home.subtitle')}
          </p>
          <p className="home-text-2">{t('home.searchTitle')}</p>

          <div className="festival-card-container">
            <Search />
          </div>
          <p className="home-text-2">{t('home.upcomingTitle')}</p>

          <div className="festival-card-container">
            {festivals.map(
              (festival, index) =>
                index < 4 && (
                  <FestivalCard key={index} festival={festival} mini={false} />
                )
            )}
          </div>
          <FestivalCalendar festivals={festivals} />
          <p className="home-text-2">
            {t('home.tagline')}
          </p>
        </div>
      </div>

  );
};

export default Home;