// App.js
import React, {useState, useEffect} from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import Header from "./components/header/header";
import Home from './pages/Home/Home';
import About from './pages/About/About';
import './App.css';
import Festival from './pages/Festival/Festival';
import Footer from './components/footer/footer';
import Explore from './pages/Explore/Explore';
import { initGA, logPageView } from './utils/analytics';
import './i18n'; // Importa la configurazione i18n
import LanguageSelector from './components/language-selector/language-selector';



function AnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    if (location) {
      logPageView(location.pathname + location.search);
    }
  }, [location]);

  return null;
}

const App = () => {
  const [minimize, setMinimize] = useState(window.scrollY);
  const { t } = useTranslation();

  useEffect(() => {
    initGA('G-9MWTNEBT3X');

    const handleScroll = () => {
      if (window.scrollY < 400) {
        setMinimize(false);
      }
      if (window.scrollY > 400) {
        setMinimize(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [minimize]);

  return (
    <div className="App">
      <AnalyticsTracker />
      <CookieConsent
        location="bottom"
        buttonText={t('cookieConsent.button')}
        cookieName="CookiesSoundTrip"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {t('cookieConsent.message')}{" "}
        <a href="/privacy-policy" style={{ color: "#ffffff" }}>
          {t('cookieConsent.learnMore')}
        </a>
      </CookieConsent>
      <LanguageSelector />
      <Header minimize={minimize} />
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/festival" element={<Explore />} />
        <Route path="/festival/:route" element={<Festival />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;