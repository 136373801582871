import React, { useState, useEffect } from 'react';

const ItalyFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="20" height="15">
    <g fillRule="evenodd" strokeWidth="1pt">
      <path fill="#fff" d="M0 0h640v480H0z"/>
      <path fill="#009246" d="M0 0h213.3v480H0z"/>
      <path fill="#ce2b37" d="M426.7 0H640v480H426.7z"/>
    </g>
  </svg>
);

const UKFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="20" height="15">
    <path fill="#012169" d="M0 0h640v480H0z"/>
    <path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z"/>
    <path fill="#C8102E" d="m424 281 216 159v40L369 281h55zm-184 20 6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z"/>
    <path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z"/>
    <path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z"/>
  </svg>
);

const LanguageSelector = () => {
  const [currentLang, setCurrentLang] = useState('it');

  useEffect(() => {
    // Recupera la lingua salvata dal localStorage all'avvio
    const savedLang = localStorage.getItem('preferred-language') || 'it';
    setCurrentLang(savedLang);
    if (window.i18n) {
      window.i18n.changeLanguage(savedLang);
    }
  }, []);

  const handleLanguageChange = (lang) => {
    setCurrentLang(lang);
    localStorage.setItem('preferred-language', lang);
    if (window.i18n) {
      window.i18n.changeLanguage(lang);
    }
    // Emette un evento custom per notificare altri componenti del cambio lingua
    window.dispatchEvent(new CustomEvent('languageChange', { detail: { language: lang } }));
  };

  return (
    <div className="language-selector">
      <button 
        onClick={() => handleLanguageChange('it')}
        className={`lang-btn ${currentLang === 'it' ? 'active' : ''}`}
        aria-label="Cambia lingua in Italiano"
      >
        <span className="flag-icon"><ItalyFlag /></span>
        <span className="lang-text">IT</span>
      </button>
      <button 
        onClick={() => handleLanguageChange('en')}
        className={`lang-btn ${currentLang === 'en' ? 'active' : ''}`}
        aria-label="Change language to English"
      >
        <span className="flag-icon"><UKFlag /></span>
        <span className="lang-text">EN</span>
      </button>
    </div>
  );
};

export default LanguageSelector;