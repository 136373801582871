import React from "react";
import { format, isValid, parse, differenceInCalendarDays } from "date-fns";
import { it } from "date-fns/locale";

const generateHotelUrl = (city, checkIn, checkOut) => {
  // Format dates for URL
  const formatDateForUrl = (dateStr) => {
    if (!dateStr) return "";
    const date = parse(dateStr, "yyyy-MM-dd", new Date());
    if (!isValid(date)) return "";

    return format(date, "yyyy-MM-dd");
  };

  const checkInFormatted = formatDateForUrl(checkIn);
  const checkOutFormatted = formatDateForUrl(checkOut);

  // Only proceed if we have valid dates
  if (!checkInFormatted || !checkOutFormatted) return "";

  const params = new URLSearchParams({
    destination: city,
    startDate: checkInFormatted,
    endDate: checkOutFormatted,
    rooms: "1",
    adults: "1",
    sort: "DISTANCE",
  });

  return `https://www.expedia.com/Hotel-Search?${params.toString()}`;
};

const ExpediaHotels = ({ city, link, checkIn, checkOut }) => {
  const formatDisplayDate = (dateStr1, dateStr2) => {
    if (!dateStr1 && !dateStr2) return "";
    const date1 = parse(dateStr1, "yyyy-MM-dd", new Date());
    const date2 = parse(dateStr2, "yyyy-MM-dd", new Date());
    if (!isValid(date1) && !isValid(date2)) return "";
    
    const month1 = format(date1, "MMMM", { locale: it });
    const month2 = format(date2, "MMMM", { locale: it });
    
    const dateString1 = month1 !== month2 ? format(date1, "d MMMM", { locale: it }) : format(date1, "d", { locale: it });
    const dateString2 = format(date2, "d MMMM", { locale: it });
    
    return `${dateString1} - ${dateString2}`;
  };

  const getDateDifference = (startDate, endDate) => {
    // Parsing delle date dal formato "yyyy-MM-dd" a oggetti Date
    const parsedStartDate = parse(startDate, "yyyy-MM-dd", new Date());
    const parsedEndDate = parse(endDate, "yyyy-MM-dd", new Date());
    
    // Calcolo della differenza in giorni
    const diffInDays = parsedStartDate!== parsedEndDate ? differenceInCalendarDays(parsedEndDate, parsedStartDate) : 1;
    
    return diffInDays;
  };

  // Generate URL
  const searchUrl = generateHotelUrl(city, checkIn, checkOut);

  // If we couldn't generate a valid URL, don't render anything
  if (!searchUrl) return null;

  return (
    
      <div className="train-info">
        <div className="train-info-wrapper">
          <span style={{ textTransform: "uppercase" }}>
            <span>
              Hotel vicini a <br /> {city} <br />
              {formatDisplayDate(checkIn, checkOut)}<br/>
            </span>
            <span>{getDateDifference(checkIn, checkOut)} {getDateDifference(checkIn, checkOut) > 1 ? 'notti' : 'notte'}</span>
          </span>
          
        </div>

        <a
          className="train-search"
          href={link ? link : "https://www.expedia.com/Hotel-Search"}
          target="_blank"
          rel="sponsored noopener noreferrer"
        >
          <span>Prenota <small style={{ color: "white" }}>su Expedia</small></span>
        </a>
      </div>
    
  );
};

export default ExpediaHotels;
