import React, { useEffect, useState } from "react";
import "./header.style.css"; // Assicurati di creare un file CSS per gli stili del componente
import logo from "../../assets/Logo.png";
import logoMini from "../../assets/Logo-mini.png"; // Importa l'immagine del logo
import spotifyIcon from "../../assets/spotify-icon.png";
import instagramIcon from "../../assets/Instagram_icon.png";
// import facebookIcon from "../../assets/Facebook-icon.png";
import { useLocation } from "react-router-dom";

const Header = ({ minimize }) => {
  const location = useLocation();
  const [active, setActive] = useState(location.pathname);

  useEffect(() => {
    setActive(location.pathname);
  }, [location]);
  return (
    <header className="header">
      <header className={minimize ? "header-mini-open" : "header-mini"}>
        <img src={logoMini} alt="Logo" className="header-logo-mini" />

        <div className="header-row-menu-mini">
          <div>
            <a
              href="/"
              className={`menu-link-white ${active === "/" ? "active" : ""}`}
            >
              HOME
            </a>
          </div>
          <div>
            <a
              href="/festival"
              className={`menu-link-white ${
                active === "/festival" ? "active" : ""
              }`}
            >
              FESTIVAL
            </a>
          </div>
          <div>
            <a
              href="/about-us"
              className={`menu-link-white ${
                active === "/about-us" ? "active" : ""
              }`}
            >
              ABOUT US
            </a>
          </div>
        </div>
      </header>
      <div className="header-column">
        <img src={logo} alt="Logo" className="header-logo" />
      </div>
      <div className="menu-wrapper">
      <div className="header-row-menu">
        
        <div>
          <a href="/" className={`menu-link ${active === "/" ? "active" : ""}`}>
            HOME
          </a>
        </div>
        <div>
          <a
            href="/festival"
            className={`menu-link ${active === "/festival" ? "active" : ""}`}
          >
            FESTIVAL
          </a>
        </div>
        <div>
          <a
            href="/about-us"
            className={`menu-link ${active === "/about-us" ? "active" : ""}`}
          >
            ABOUT US
          </a>
        </div>
       
        </div>
      <div className="icons-wrapper">
          <a
            href="https://www.instagram.com/soundtrip.online"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagramIcon} alt="Logo" className="icon" />
          </a>
          {/* <a href="https://www.instagram.com/soundtrip.online" target="_blank" rel="noreferrer">
          <img
            src={facebookIcon}
            alt="Logo"
            className="icon"
          />
          </a> */}
          <a
            href="https://www.instagram.com/soundtrip.online"
            target="_blank"
            rel="noreferrer"
          >
            <img src={spotifyIcon} alt="Logo" className="icon-header" />
          </a>
        </div>
      
        
      </div>
    </header>
  );
};

export default Header;
