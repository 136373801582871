import React from "react";
import "./About.style.css";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
      <Helmet>
        <title>About Us | SoundTrip</title>
        <meta
          name="description"
          content="Scopri chi siamo e la nostra missione di connettere gli appassionati di musica con i migliori festival in Italia."
        />
        <link rel="canonical" href="https://soundtrip.online/about-us" />
      </Helmet>
      <div className="about-container">
        <h1 style={{ margin: "2rem", fontSize: "1.5rem" }}>
          Ehi Festival Lover!
        </h1>
        <h2 className="festival-title">Musica + Viaggi = Magia 🪄</h2>
        <p style={{ textAlign: "justify", fontSize: "1.5rem" }}>
          Benvenuto/a nel tuo nuovo punto di riferimento per esplorare il meglio
          della musica live in Italia. <strong>SoundTrip</strong> nasce per
          rendere la tua esperienza festival semplicemente perfetta. Tutti i
          principali festival in un'unica piattaforma
        </p>
        <h2 className="festival-title">Scopri e Vivi i Migliori Festival 🎪</h2>
        <p>🎸 Lineup sempre aggiornate</p>
        <p>🚊 Collegamenti e trasporti verificati</p>
        <p>🏕️ Info dettagliate su ogni evento</p>
        <h2 className="festival-title">
          I Numeri che raccontano i Festival 📈
        </h2>
        <p>🎶 70 festival mappati</p>
        <p>📌 150 città connesse</p>
        <p>🛩️ 20 aeroporti collegati</p>
      </div>
    </>
  );
};

export default About;
