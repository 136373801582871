import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import festivals from "../../assets/utils/festivals.json";
import "./Festival.style.css";
import TravelSearch from "../../components/travel-search/travel-search";
import map from "../../assets/map.png";
import linkIcon from "../../assets/website-icon.png";
import { Helmet } from "react-helmet";
import FestivalCountdown from "../../components/countdown/countdown";
import ShareButton from "../../components/share-button/share-button";

const Festival = () => {
  const { route } = useParams();
  const [festival, setFestival] = useState(null);
  const [open, setOpen] = useState(0);

  useEffect(() => {
    // Trova il festival con l'ID corrispondente
    const foundFestival = festivals.find(
      (festival) => festival?.name.replace(/\s+/g, "-").toLowerCase() === route
    );
    setFestival(foundFestival);
  }, [route]);

  const structuredData = festival
    ? {
        "@context": "https://schema.org",
        "@type": "MusicEvent",
        name: festival.name,
        startDate: festival.startDate,
        endDate: festival.endDate,
        location: {
          "@type": "Place",
          name: festival.location[0],
          address: {
            "@type": "PostalAddress",
            addressLocality: festival.city,
            addressCountry: "IT",
          },
        },
        performer: festival.lineup?.map((artist) => ({
          "@type": "MusicGroup",
          name: artist,
        })),
        offers: festival.tickets?.map((ticket) => ({
          "@type": "Offer",
          price: ticket.minPrice,
          priceCurrency: "EUR",
          url: ticket.ticketLink,
          availability: "https://schema.org/InStock",
        })),
        image: festival.card,
        description: festival.info,
        eventStatus: "https://schema.org/EventScheduled",
      }
    : null;

  return (
    <>
      <Helmet>
        {/* Meta tag di base */}
        <title>{`${festival?.name} 2025: Date, Biglietti, Line-up | SoundTrip`}</title>
        <meta
          name="description"
          content={`${festival?.name} ${
            festival?.year
          }: acquista i biglietti, consulta date, prezzi e line-up completa. ${festival?.lineup
            ?.slice(0, 3)
            .join(", ")} e altri artisti in concerto a ${festival?.city}.`}
        />
        <meta
          name="keywords"
          content={`${festival?.name}, festival ${
            festival?.year
          }, ${festival?.lineup?.join(", ")}, concerti ${
            festival?.city
          }, biglietti ${festival?.name}`}
        />

        {/* Open Graph tags */}
        <meta
          property="og:title"
          content={`${festival?.name} ${festival?.year} | Line-up e Biglietti`}
        />
        <meta
          property="og:description"
          content={`${festival?.name} ${festival?.year} a ${
            festival?.city
          }. Line-up: ${festival?.lineup?.join(
            ", "
          )}. Info, date e biglietti su SoundTrip`}
        />
        <meta property="og:image" content={festival?.card} />
        <meta property="og:type" content="music.festival" />
        <meta
          property="og:url"
          content={`https://soundtrip.it/festival/${route}`}
        />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`${festival?.name} ${festival?.year}`}
        />
        <meta
          name="twitter:description"
          content={`Line-up, date e biglietti per ${festival?.name} ${festival?.year} a ${festival?.city}`}
        />
        <meta name="twitter:image" content={festival?.card} />

        <link rel="canonical" href={`https://soundtrip.it/festival/${route}`} />

        {/* Structured Data */}
        {festival && (
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        )}
      </Helmet>
      <div className="festival-container">
        <h1 className="festival-name">{festival?.name}</h1>
        <p className="festival-info">{festival?.info}</p>
        <FestivalCountdown startDate={festival?.startDate} />
        {!festival?.tickets && <span>Coming soon</span>}
        {festival && <TravelSearch festival={festival} />}
        {festival?.playlistLink && (
          <div className="spotify-player-festival">
            <iframe
              title="spotify-playlist"
              style={{
                "border-radius": "10px",
                boxShadow: "1px 3px 3px rgba(0, 0, 0, 0.1)",
              }}
              src={festival?.playlistLink}
              width="100%"
              height="352"
              frameBorder="0"
              allowfullscreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            ></iframe>
          </div>
        )}
        <div className="menu">
          <div className="site-link">
            <img src={linkIcon} className="link-icon" alt="link"></img>
            <a
              rel="noreferrer"
              href={festival?.link}
              target="_top"
              className="link"
            >
              <span>Visita il sito del festival</span>
            </a>
          </div>
          <ShareButton festival={festival} />
          {!open && (
            <div style={{ backgroundImage: `url(${map})` }} className="map">
              <div className="map-title">
                <span>{`${festival?.location[0]}, ${festival?.city}`}</span>
              </div>
              <div className="open-map-button-container">
                <button className="open-map-button" onClick={() => setOpen(1)}>
                  Apri su <span className="site-link-button">Google Maps</span>
                </button>
              </div>
            </div>
          )}

          {open === 1 && (
            <div
              className="map-iframe"
              style={{
                maxHeight: "350px",
                maxWidth: "500px",
                width: "100%",
                height: "100%",
              }}
            >
              <iframe
                title="map"
                width="100%"
                frameBorder={0}
                height="300px"
                zoom={14}
                referrerPolicy="no-referrer-when-downgrade"
                src={festival?.src}
                allowFullScreen
              ></iframe>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Festival;
