import React from "react";
import { format, isValid, parse, differenceInCalendarDays } from "date-fns";
import "./booking.style.css";
import { it } from "date-fns/locale";

const generateHotelUrl = (city, checkIn, checkOut) => {
  // Format dates for Booking.com URL format (yyyy-MM-dd)
  const formatDateForUrl = (dateStr) => {
    if (!dateStr) return "";
    const date = parse(dateStr, "yyyy-MM-dd", new Date());
    if (!isValid(date)) return "";
    return format(date, "yyyy-MM-dd");
  };
 

  const checkInFormatted = formatDateForUrl(checkIn);
  const checkOutFormatted = formatDateForUrl(checkOut);

  // Only proceed if we have valid dates
  if (!checkInFormatted || !checkOutFormatted) return "";

  // Booking.com URL parameters
  const params = new URLSearchParams({
    ss: `${city}, Italia`,          // Search string with country
    lang: "it",                     // Language Italian
    sb: "1",                        // Search box
    src_elem: "sb",                 // Source element
    src: "index",                   // Source page
    group_adults: "1",              // Number of adults
    no_rooms: "1",                  // Number of rooms
    group_children: "0",            // Number of children
    checkin: checkInFormatted,      
    checkout: checkOutFormatted,
    aid: "2311236"  // Label for tracking
  });

  return `https://www.booking.com/searchresults.it.html?${params.toString()}`;
};

const getDateDifference = (startDate, endDate) => {
  // Parsing delle date dal formato "yyyy-MM-dd" a oggetti Date
  const parsedStartDate = parse(startDate, "yyyy-MM-dd", new Date());
  const parsedEndDate = parse(endDate, "yyyy-MM-dd", new Date());
  
  // Calcolo della differenza in giorni
  const diffInDays = differenceInCalendarDays(parsedEndDate, parsedStartDate);
  
  return diffInDays;
};

const BookingHotels = ({ city, link, checkIn, checkOut }) => {
  const formatDisplayDate = (dateStr1, dateStr2) => {
    if (!dateStr1 && !dateStr2) return "";
    const date1 = parse(dateStr1, "yyyy-MM-dd", new Date());
    const date2 = parse(dateStr2, "yyyy-MM-dd", new Date());
    if (!isValid(date1) && !isValid(date2)) return "";
    
    const month1 = format(date1, "MMMM", { locale: it });
    const month2 = format(date2, "MMMM", { locale: it });
    
    const dateString1 = month1 !== month2 ? format(date1, "d MMMM", { locale: it }) : format(date1, "d", { locale: it });
    const dateString2 = format(date2, "d MMMM", { locale: it });
    
    return `${dateString1} - ${dateString2}`;
  };

  // Generate URL
  const searchUrl = generateHotelUrl(city, checkIn, checkOut);

  // If we couldn't generate a valid URL, don't render anything
  if (!searchUrl) return null;

  return (
    <div className="train-info">
      <div className="train-info-wrapper">
        <span style={{ textTransform: "uppercase" }}>
          <span>
            {city} <br />
            {formatDisplayDate(checkIn, checkOut)}<br/>
          </span>
          <span>{getDateDifference(checkIn, checkOut)} {getDateDifference(checkIn, checkOut) > 1 ? 'notti' : 'notte'}</span>
        </span>
      </div>

      <a
        className="train-search"
        href={link ? link : searchUrl}
        target="_blank"
        rel="sponsored noopener noreferrer"
      >
        <span>Prenota <small style={{ color: "white" }}>su Booking</small></span>
      </a>
    </div>
  );
};

export default BookingHotels;